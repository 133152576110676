class TPreferencia{        
    FiltrosSQLObter() {
        let vFiltros;
        if (localStorage.getItem('@sysclesiaapp/PREF_FiltrosSQL') !== null)
        {
            vFiltros = localStorage.getItem('@sysclesiaapp/PREF_FiltrosSQL').split(",");
        } 
        else
        {
            vFiltros = [];
        }       
        
        return vFiltros;
    }
    FiltrosSQLSalvar(pListagem) {      
        localStorage.setItem('@sysclesiaapp/PREF_FiltrosSQL', pListagem);
    }  
    ServidorURLObter() {      
        let vURL = "";
        vURL = localStorage.getItem('@sysclesiaapp/SrvURL');
        if (vURL == null)
        {
            vURL = window.location.hostname;            
        }

        return vURL;
    }  
    ServidorURLSalvar(pServidorURL) {      
        localStorage.setItem('@sysclesiaapp/SrvURL', pServidorURL);
    } 
    ServidorPortaObter() {      
        let vPorta = 0;
        vPorta = localStorage.getItem('@sysclesiaapp/SrvPorta');
        if (vPorta == null)
        {
            vPorta = 8082;            
        }

        return vPorta;
    }  
    ServidorPortaSalvar(pServidorPorta) {      
        localStorage.setItem('@sysclesiaapp/SrvPorta', pServidorPorta);
    }             
}

let vgPreferencia = new TPreferencia();

export default vgPreferencia;