import axios from 'axios';

import vgPreferencia from './preferencias';

function RetornaURL () {
    let vURL= "";
    vURL = window.location.protocol+"//"+vgPreferencia.ServidorURLObter()+":"+ vgPreferencia.ServidorPortaObter()+"/api";
    //console.log(vURL);
		return vURL;
}

const api = axios.create({ baseURL: RetornaURL() });
export default api;