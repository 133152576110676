import React, { Component } from 'react';

import { Tab, Container, Nav, Row } from 'react-bootstrap';

import api from '../../service/api';
import { Base64 } from 'js-base64';
import vgTransferencia from '../../service/transferencia';
import vgUtils from '../../service/utils';
import vgPreferencia from '../../service/preferencias';
import vgLogin from '../../service/login';

class CpCorpo extends Component {

    constructor(props) {
        super(props);
        this.btnLimparFiltroClick = this.btnLimparFiltroClick.bind(this);
        this.btnFiltroClick = this.btnFiltroClick.bind(this);
        this.txtFiltroChange = this.txtFiltroChange.bind(this);
        this.selRelatorioChange = this.selRelatorioChange.bind(this);
        this.btnSairClick = this.btnSairClick.bind(this);
        this.btnEditarMembroClick = this.btnEditarMembroClick.bind(this);
        this.btnClickImpressao = this.btnClickImpressao.bind(this);
        this.btnClickADDFiltro = this.btnClickADDFiltro.bind(this);
        this.selClickFiltroSQL = this.selClickFiltroSQL.bind(this);

        this.state = {            
            vPacessosis: {},
            vRelatorio: 'selecione',
            vErro: true,
            vMsgErro: '',
            vFiltro: '',
            vFiltroCadastrado: [],
            vMembros: []
        };
    } 
    
    componentDidMount(){
        this.setState({vFiltroCadastrado: vgPreferencia.FiltrosSQLObter()});
        this.AlimentarAcessoSIS();
    }

    AlimentarAcessoSIS() {
        this.setState({ vPacessosis: {isLoggedIn: vgLogin.isLoggedIn, 
                                      user: vgLogin.user, 
                                      password: vgLogin.password, 
                                      keyaccess: vgLogin.keyaccess, 
                                      limitaccess: vgLogin.limitaccess,  
                                      message: vgLogin.message                                    
                                     } });
    }

    async ObterMembros(e) {
      await api.get(`/retlimite?filtro=${Base64.encode(this.state.vFiltro)}&pacessosis=${Base64.encode(JSON.stringify(this.state.vPacessosis))}`)
          .then(response => {
              this.setState(response.data);
          })
          .catch(error => {
              this.setState({ vMsgErro: 'Servidor não encontrado tente mais tarde' });
          });
    }

    btnClickADDFiltro(pADD) {        
        let vFiltrosTmp = [];    
       
        if (pADD)
        {    
            for (var vFiltro in this.state.vFiltroCadastrado) {             
                vFiltrosTmp.push(this.state.vFiltroCadastrado[vFiltro]);
             };            
            
            vFiltrosTmp.push(this.state.vFiltro);
        }
        else
        {            
            for (var vFiltroT in this.state.vFiltroCadastrado) {             
               if (this.state.vFiltroCadastrado[vFiltroT] !== this.state.vFiltro)
               {                
                  vFiltrosTmp.push(this.state.vFiltroCadastrado[vFiltroT]);
               }
            };      
        };
        
        //console.log(vFiltrosTmp);         
        vgPreferencia.FiltrosSQLSalvar(vFiltrosTmp);
        
        this.setState({vFiltroCadastrado: vFiltrosTmp});
    }

    btnClickImpressao() {
        if (this.state.vRelatorio !== 'selecione')
        {
            api.get(`/impressao?relatorio=${Base64.encode(this.state.vRelatorio)}&filtro=${Base64.encode(this.state.vFiltro)}&pacessosis=${Base64.encode(JSON.stringify(this.state.vPacessosis))}`,
            ).then((response) => {
                vgUtils.FileDownloadBase64(response.data.vArquivo, response.data.vNomeArquivo);
            });
        }
    }

    btnEditarMembroClick(pID) {
        vgTransferencia.tipo = 'membro';
        vgTransferencia.id = pID;
        window.location = '/membro';
    }

    btnSairClick() {
        sessionStorage.removeItem('@sysclesiaapp/isLoggedIn');
        window.location.reload();
    }

    selRelatorioChange(e) {
        this.setState({ vRelatorio: e.target.value })
    }

    btnLimparFiltroClick() {
        this.setState({ vFiltro: '' });
        //console.log('btnLimparFiltroClick');    
        //console.log(this.state.vMembros);
        this.ObterMembros('');
    }

    btnFiltroClick() {
        //this.setState({ vFiltro: Base64.encode(e.target.value)});     
        //console.log(this.state.vFiltro);
        this.ObterMembros(this.state.vFiltro);
    }

    txtFiltroChange(e) {
        this.setState({ vFiltro: e.target.value });
    }

    selClickFiltroSQL(e) {
        //console.log(e.target.value);
        this.setState({ vFiltro: e.target.options[e.target.selectedIndex].text });     
    }

    render() {        
        const vMembros = this.state.vMembros;
        const vErro = this.state.vErro;
        const vMsgErro = this.state.vMsgErro;
        const vListaFiltros = this.state.vFiltroCadastrado;
        return (
            <Container className="mt-2">
                <Tab.Container id="left-tabs-example" defaultActiveKey="pg-Filtro">
                    <Row >
                        <Nav variant="pills" >
                            <Nav.Item>
                                <Nav.Link eventKey="pg-Filtro">Filtros</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="pg-Cadastro">Cadastro</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="pg-Impressao">Impressão</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="pg-Administracao" href="/configuracoes">Configurações</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <div className="col d-flex align-items-start justify-content-end">
                            <input type="button" className="btn btn-secondary" value="Sair" onClick={this.btnSairClick} />
                        </div>
                        <Container>
                            <Tab.Content>
                                <Tab.Pane eventKey="pg-Filtro">
                                    <div className="form-group">
                                        <div className="form-row">
                                            <div className="col-sm-12 col-md-5">
                                                <span>Filtro manual:</span>
                                                <input type="text" className="form-control" value={this.state.vFiltro} onChange={this.txtFiltroChange} />
                                            </div>
                                            <div className="col-sm-12 col-md-1 mt-2 mt-md-4">
                                                <input type="button" className="form-control btn btn-primary" value="Filtrar" onClick={this.btnFiltroClick} />
                                            </div>
                                            <div className="col-sm-12 col-md-1 mt-2 mt-md-4">
                                                <input type="button" className="form-control btn btn-secondary" value="Todos" onClick={this.btnLimparFiltroClick} />
                                            </div>
                                            <div className="col-sm-12 col-md-4 mt-1 mt-md-0">
                                                <span>Filtros:</span>    
                                                <select className="form-control" onChange={this.selClickFiltroSQL}>                                                
                                                    <option key="id0" value="ns">Selecione</option>
                                                    {vListaFiltros.map(vADDFiltro => (
                                                        <option>{vADDFiltro}</option>
                                                    ))}                                                                                              

                                                </select>
                                            </div>
                                            <div className="col-sm-4 col-md-1 mt-md-4 mt-2 btn-group" role="group">
                                                <input type="button" className="btn btn-primary form-control" value="+" onClick={() => this.btnClickADDFiltro(true)} />
                                                <input type="button" className="btn btn-primary form-control" role="group" value="-" onClick={() => this.btnClickADDFiltro(false)} />
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="pg-Cadastro">
                                    <div className="form-group">
                                        <div className="form-row">
                                            <input type="button" className="btn btn-primary" value="Novo" onClick={() => this.btnEditarMembroClick(-1)} />
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="pg-Impressao">
                                    <div className="form-group">
                                        <div className="form-row">
                                            <label>Relatório:</label>
                                        </div>
                                        <div className="form-row">
                                            <select className="form-control col-md-6" value={this.state.vRelatorio} onChange={this.selRelatorioChange}>
                                                <option value="selecione">Selecione</option>
                                                <option value="FichaInscricao">Ficha de inscrição</option>
                                                <option value="CartaoMembro">Cartão de membro</option>
                                                <option value="Carodromo">Carodromo</option>
                                                <option value="CertificadoBatismo">Certificado batismo</option>
                                                <option value="CertificadoPresbitero">Certificado presbítero</option>
                                                <option value="CertificadoApresentacao">Certificado apresentação</option>
                                            </select>
                                        </div>
                                        <div className="form-row">
                                            <input type="button" className="btn btn-secondary mt-1" value="Impressão" onClick={this.btnClickImpressao} />
                                        </div>

                                    </div>
                                </Tab.Pane>
                            </Tab.Content>

                        </Container>

                    </Row>
                </Tab.Container>

                <div className="tab-content mt-3">
                </div>

                <hr className="my-4" />
                {
                    vErro ? (<h5>{vMsgErro}</h5>) : (
                        <table className="table table-hover table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Matrícula</th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Congregação</th>
                                    <th scope="col">Atividade</th>
                                    <th scope="col">Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vMembros.map(vMembro => (
                                    <tr>
                                        <td>{vMembro.pk_membro}</td>
                                        <td>{vMembro.matricula}</td>
                                        <td>{vMembro.nome_completo}</td>
                                        <td>{vMembro.congregacao}</td>
                                        <td>{vMembro.atividade}</td>
                                        <td><button className="btn btn-secondary" onClick={() => this.btnEditarMembroClick(vMembro.pk_membro)}>Editar</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )
                }
            </Container>
        )
    }
}

export default CpCorpo;