import React, { Component } from 'react';
import api from '../../service/api';
import { Base64 } from 'js-base64';
import { sha256 } from 'js-sha256';
import ReCAPTCHA from "react-google-recaptcha";

import { IoIosContact } from "react-icons/io";
import vgLogin from '../../service/login';


class FrmLogin extends Component {

	constructor(props) {
		super(props);

		this.txtChangeUser = this.txtChangeUser.bind(this);
		this.txtChangePassword = this.txtChangePassword.bind(this);
		this.btnClickLogin = this.btnClickLogin.bind(this);
		this.validarRobo = this.validarRobo.bind(this);

		this.state = {
			isLoggedIn: false,
			user: '',
			password: '',
			keyaccess: '',
			limitaccess: '',
			message: 'Digite seu usuário e senha.',
			notrobot: false,
		}
	}

	ProcessarResposta() {
		vgLogin.isLoggedIn = this.state.isLoggedIn;
		vgLogin.user = Base64.decode(this.state.user);
		vgLogin.password = this.state.password;
		vgLogin.keyaccess = Base64.decode(this.state.keyaccess);
		vgLogin.limitaccess = new Date(this.state.limitaccess);
		vgLogin.message = this.state.message;

		this.setState({ user: vgLogin.user, keyaccess: vgLogin.keyaccess });
	}

	async valida() {    
		this.setState({ message: 'Aguarde, obtendo dados.' });
		if ((this.state.user !== '') || (this.state.password !== '')) {
			await api.get(`/validarlogin?pacessosis=${Base64.encode(JSON.stringify(this.state))}`)
				.then(response => {

					this.setState(response.data);
					this.ProcessarResposta();
				})
				.catch(error => {
					this.setState({ message: 'Servidor não foi encontrado' });
					document.getElementById("txtSenha").value = '';
					document.getElementById("txtUsuario").value = '';
				});
		}
		else {
			this.setState({ message: 'Digite seu usuário e senha.' });
		}
	}

	async btnClickLogin(e) {
		//this.props.btnClickLogin(e.target.value);      
		if (this.state.notrobot === true) {
			this.setState({ message: 'Aguarde, obtendo dados.' });

			await this.valida();

			if (this.state.isLoggedIn) {
				this.props.history.push("/");
			}
			else {
				//this.LerLogin();
			}
		}
		else
		{
			this.setState({ message: 'Valide o CAPTCHA.' });	
		}
	}

	txtChangeUser(e) {
		//this.props.ontxtUserChange(e.target.value);
		//this.setState({ user: e.target.value });         
		this.setState({ user: e.target.value });
	}

	txtChangePassword(e) {
		//this.props.ontxtPasswordChange(sha256(e.target.value));  
		this.setState({ password: sha256(e.target.value) });
		//this.setState({ password: sha256(e.target.value) });
	}

	validarRobo (e) {
		this.setState({ notrobot: true });		
	}

	render() {
		return (
			<div className="container form-group">
				<div className="col-lg-4 mx-auto card mt-4">
					<div className="fadeIn first text-center">
						<IoIosContact color="gray" size="70" />
					</div>

					<h2 className="card-title text-primary text-center">Login</h2>

					<div className="card-body">
						<input type="text" className="form-control text-center mb-1" value={this.state.user} placeholder="usuário" onChange={this.txtChangeUser} id="txtUsuario" />
						<input type="password" className="form-control text-center mb-1" placeholder="senha" onChange={this.txtChangePassword} id="txtSenha" />
						<input type="button" className="btn btn-primary col-12 mb-2" value="Entrar" onClick={this.btnClickLogin} disabled={!this.state.notrobot}  />
						<ReCAPTCHA sitekey="6LfL_ychAAAAAIVl8jipXzcR2FxH284at_QLKFZS" onChange={this.validarRobo} /> 
					</div>
					<div className="card-footer text-muted font-italic mx-auto">
						<label>{this.state.message}</label>
					</div>

					{/* <div>
						<a className="primary" href="\configuracoes"> Configurações</a>
					</div> */}
				</div>
			</div>
		)
	}
}

export default FrmLogin;