import React, { Component } from 'react';

import CpCorpo from '../corpo/CpCorpo';

class FrmPrincipal extends Component {    

    render() {
        return (           
            <CpCorpo></CpCorpo>                         
        )
    }
}

export default FrmPrincipal;