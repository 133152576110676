import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import FrmLogin from './components/login/FrmLogin';
import FrmPrincipal from './components/principal/FrmPrincipal';
import Membro from './components/membro/FrmMembro';
import vgLogin from './service/login';
import ConfigAcesso from './components/configuracoes/ConfigAcesso';


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}   
    render={props =>       
      vgLogin.isLoggedIn ? (        
        <Component {...props}/>
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )
    }
  />
);

function App() {  
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path='/' exact component={FrmPrincipal} />
        <Route path='/login' component={FrmLogin} />
        <PrivateRoute path='/principal' component={FrmPrincipal} />        
        <PrivateRoute path='/membro' component={Membro} />
        <Route path='/configuracoes' exact component={ConfigAcesso} />        
        <Route path='*' component={() => <h1>Página não existe</h1>} />        
      </Switch>
    </BrowserRouter>
  );
}

export default App;
