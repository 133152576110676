
class TUtils{   
    DelphiToDate(pTDate) { 			
			  //Data inicial do delphi é anterior a do JS, coloco na mesma posicao inicial de data
        let vDT = new Date(1899,11,30,0,0,0,0);      
        //Agora adiciono os dias      
        vDT.setMilliseconds(86400*1000*pTDate);   
        let vDia = vDT.getDate();
        let vMes = vDT.getMonth(); 
        let vAno = vDT.getFullYear();
        vDT = new Date(vAno, vMes, vDia, 0,0,0,0);   				
        return vDT; 
    }
		DelphiTimestampToDate(pData) {			
			let vDtDelphi = new Date(pData);			
			vDtDelphi.setMinutes(vDtDelphi.getMinutes() + vDtDelphi.getTimezoneOffset());
			return vDtDelphi;
		}		
    DateToStr(pData) {        
        let vDia = String("0" + pData.getDate()).slice(-2);
        let vMes = String("0" + (parseInt(pData.getMonth())+1)).slice(-2);         
        let vAno = pData.getFullYear().toString();        
        let vStrDt = vAno+"-"+vMes+"-"+vDia;
        return vStrDt;
    }
		DateTimeToStr(pDataString) {
			if (pDataString != undefined) {		
				return pDataString.substring(0, 16);
			} else {
				return '';
			}			
		}	
		DateMaxToStr(pDataString) {
			let vDt = new Date();		
			return vDt.toJSON().substring(0, 10);
		}	
		DateTimeMaxToStr(pDataString) {
			let vDt = new Date();		
			return vDt.toJSON().substring(0, 16);
		}						
		DelphiTimestampToJS(pData) {
			let vDT = new Date(pData * 1000);
			return vDT;
		}		
    DateToDelphi(pDataJS) {			
			const UnixDateDelta = 25569.0;
			const SecsPerDay = 86400;
			const MSecsPerSec = 1000;
			var UnixSeconds = pDataJS.getTime() / MSecsPerSec; // 1563984000
			var SecsToAdd = UnixSeconds / SecsPerDay; // 18101.666666666668		
			return UnixDateDelta + SecsToAdd;
        //Data inicial a mesma do delphi
        //let vDiaPas = new Date(1899,11,30,0,0,0,0);
        //let vQtnDias = 0
        //vQtnDias = Math.ceil((pDataJS - vDiaPas) / (86400*1000));

        //return vQtnDias;
    }
    FileToStrBase64(pFile){             
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsBinaryString(pFile);
            reader.onload = () => resolve(btoa(reader.result));
            reader.onerror = error => reject(error);
          });        
    }     
    FileDownloadBase64(pSrtBase64File, pNomeArquivo) {
        const byteCharacters = atob(pSrtBase64File);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]);  

        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', pNomeArquivo); //or any other extension
        document.body.appendChild(link);
        link.click();        
    }
}

let vgUtils = new TUtils();

export default vgUtils;