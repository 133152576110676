class TAreaTranferencia{ 
    get tipo() {                
        return sessionStorage.getItem('@sysclesiaapp/ATtipo');
    }
    set tipo(pValor) { 
        sessionStorage.setItem('@sysclesiaapp/ATtipo', pValor);
    }  
    
    get id() {                
        return sessionStorage.getItem('@sysclesiaapp/ATid');
    }
    set id(pValor) { 
        sessionStorage.setItem('@sysclesiaapp/ATid', pValor);
    } 
}

let vgTransferencia = new TAreaTranferencia();

export default vgTransferencia;