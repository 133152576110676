import { Base64 } from 'js-base64';

class TLogin{ 
    get isLoggedIn() {                
        return (sessionStorage.getItem('@sysclesiaapp/isLoggedIn') == 'true');
    }
    set isLoggedIn(pValor) { 
        sessionStorage.setItem('@sysclesiaapp/isLoggedIn', pValor);
    }

    get user() {                
        return sessionStorage.getItem('@sysclesiaapp/user');
    }
    set user(pValor) {                
        sessionStorage.setItem('@sysclesiaapp/user', pValor);
    } 

    get password() {                
        return sessionStorage.getItem('@sysclesiaapp/password');
    }
    set password(pValor) {                  
        sessionStorage.setItem('@sysclesiaapp/password', pValor);
    } 

    get keyaccess() {                
        return sessionStorage.getItem('@sysclesiaapp/keyaccess');
    }
    set keyaccess(pValor) {  
        sessionStorage.setItem('@sysclesiaapp/keyaccess', pValor);        
    } 

    get limitaccess() {        
        return sessionStorage.getItem('@sysclesiaapp/limitaccess');
    }
    set limitaccess(pValor) {               
        sessionStorage.setItem('@sysclesiaapp/limitaccess', pValor);
    }   

    get message() {                
        return sessionStorage.getItem('@sysclesiaapp/message');
    }
    set message(pValor) { 
        sessionStorage.setItem('@sysclesiaapp/message', pValor);  
    }           

    EnviarLoginJson () {
        let vLogJson = {};

        vLogJson.isLoggedIn = this.isLoggedIn;
        vLogJson.user = this.user;
        vLogJson.password = this.password;
        vLogJson.keyaccess = this.keyaccess;
        vLogJson.limitaccess = this.limitaccess;
        vLogJson.message = this.message;

        return Base64.encode(JSON.stringify(vLogJson));
    }
}

let vgLogin = new TLogin();

export default vgLogin;