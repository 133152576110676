import React, { Component } from 'react';

import { Container, Modal, Button } from 'react-bootstrap';
import vgPreferencia from '../../service/preferencias';

class ConfigAcesso extends Component {

    constructor(props) {
        super(props);

        this.btnClickSalvar = this.btnClickSalvar.bind(this);
        this.txtChangeServidorURL = this.txtChangeServidorURL.bind(this);
        this.txtChangeServidorPorta = this.txtChangeServidorPorta.bind(this);
        this.exibirDialogo = this.exibirDialogo.bind(this);
        this.btnClickHome = this.btnClickHome.bind(this);

        this.state = {
            vServidor: "",
            vPorta: 0,
            vExibirDialogo: false,
            vErro: false,
            vMsg:"",
        };
    }

    componentDidMount() {
        this.setState({ vServidor: vgPreferencia.ServidorURLObter(), vPorta: vgPreferencia.ServidorPortaObter() });
    }

    btnClickSalvar() {
        vgPreferencia.ServidorURLSalvar(this.state.vServidor);
        vgPreferencia.ServidorPortaSalvar(this.state.vPorta);
        this.setState({ vExibirDialogo: true, vMsg: "Salvo com sucesso." });
    }

    txtChangeServidorURL(e) {
        this.setState({ vServidor: e.target.value });
    }

    txtChangeServidorPorta(e) {
        this.setState({ vPorta: e.target.value });
    }

    exibirDialogo(pExibir, pMsg) {
        this.setState({ vExibirDialogo: pExibir, vMsg: pMsg });  
    }

    btnClickHome() {
        var vURL = window.location.origin;       
        window.location.href = vURL;
    }

    render() {
        return (
            <Container>
                <fieldset>
                    <legend className="mt-2">Configuraçao de acesso:</legend>
                    <div className="form-row">
                        <div className="col-md-6">
                            <span className="col-form-label-sm" id="Label20">Servidor:</span>
                            <input type="text" className="form-control form-control-sm" value={this.state.vServidor} onChange={this.txtChangeServidorURL} id="txt_servidor" />
                        </div>
                        <div className="col-md">
                            <span className="col-form-label-sm" id="Label21" >Porta:</span>
                            <input type="number" className="form-control form-control-sm" value={this.state.vPorta} onChange={this.txtChangeServidorPorta} id="txt_porta" />
                        </div>
                    </div>
                </fieldset>
                <input type="button" className="btn btn-primary col-3 mt-1" value="Salvar" onClick={this.btnClickSalvar} />
                <input type="button" className="btn btn-secondary col-3 mt-1 ml-1" value="Voltar" onClick={this.btnClickHome} />

                <Modal show={this.state.vExibirDialogo} onHide={(e) => this.exibirDialogo(false, "")}>

                    <Modal.Header closeButton className={this.state.vErro ? "alert-warning" : "alert-primary"}>
                        <Modal.Title>Aviso</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className={this.state.vErro ? "text-warning" : "text-primary"}>{this.state.vMsg}</p>
                    </Modal.Body>

                    <Modal.Footer >
                        <Button variant={this.state.vErro ? "warning" : "primary"} onClick={(e) => this.exibirDialogo(false, "")}>Fechar</Button>
                    </Modal.Footer>
                </Modal>

            </Container>
        )
    }
}

export default ConfigAcesso;